<template>
  <v-container>
    <v-form ref="form" v-model="valid">
      <v-card
        class="pa-md-2 mx-lg-auto"
        outlined
      >

        <v-row>

          <v-col
            cols="12"
            md="12"
          >
            <v-file-input
              v-model="attachment.file"
              placeholder="Upload File Lampiran"
              label="File input"
              show-size
              single
              prepend-icon="mdi-paperclip"
              :rules="[rules.required]"
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  small
                  label
                  color="primary"
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>

           <v-col
            cols="12"
            md="12"
          >
          <v-textarea
              v-model="attachForm.notes"
              label="NOTES"
              outlined
              rows="4"
              counter
              :rules="[rules.required]"
            ></v-textarea>  

          </v-col>


        </v-row>
        <v-row>
          <v-col class="text-right">
            <v-btn
              color="warning"
              class="mr-4"
              @click="resetForm()"
            >
              Reset Form
            </v-btn>
            <v-btn
              color="grey"
              class="mr-4"
              @click="doCancel()"
            >
              Batal
            </v-btn>

            <v-btn :disabled="!valid" 
              color="primary"
              class="mr-4"
              @click="saveAddForm()"
            >
              Upload
            </v-btn>
          
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <span style="font-size:8px;">{{idLetter}}</span>

    <v-snackbar
        v-model="toast.show"
        :timeout="toast.timeout"
      >
        {{ toast.text }}
        <v-btn
          :color="toast.color"
          text
          @click="toast.show = false"
        >
          Close
        </v-btn>
      </v-snackbar>

      <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

       <v-dialog v-model="isupLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="overline mb-4">Uploading File</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                            v-model="uploadPercentage"
                            height="25"
                            reactive
                          >
                          <strong>{{ Math.ceil(uploadPercentage) }}%</strong>
                        </v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="dialogConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin File tersebut sudah benar?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogConfirm = false">Batal</v-btn>
            <v-btn color="blue darken-1" @click="insertUploadSurat()">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</v-container>
</template>

<script>
import secureStorage from '@/utils/secureStorage'
import { insertLetterInOutComment } from '@/api/letterinout'
import { insertMasterAttachment } from '@/api/masterattachment'
import { _GLOBAL } from '@/api/server'
import moment from 'moment'
import axios from 'axios'

const MsAttachmentForm = {
  moduletypes: 1, // Persuratan
  id_module_data: 0,
  notes: ''
}

export default {
  name: 'FormUpload',
  components: {
  },
  props: {
    idLetter: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      attachForm: Object.assign({}, MsAttachmentForm),
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      valid: false,
      rules: {
        required: v => !!v || 'Required.',
        maxlength: v => v.length <= 240 || 'Max 240 characters',
      },
      isLoading: false,
      dialogConfirm: false,
      currrole: [],
      attachment: {
        name: '',
        file: null,
        url: ''
      },
      uploadPercentage: 0,
      isupLoading: false,
    }
  },
  created () {
    this.attachForm = Object.assign({}, MsAttachmentForm)
  },
  destroyed () {
  },
  mounted () {
    const vm = this

    vm.currrole = secureStorage.getItem('currRole')
    // console.log(vm.currrole)
    vm.attachForm = Object.assign({}, MsAttachmentForm)

  },
  computed: {

  },
  methods: {
    resetForm () {
      this.$refs.form.reset()
      this.attachForm = Object.assign({}, MsAttachmentForm)
    },
    doCancel(){
      // console.log('back')
      this.$emit('dialogAttachmentClose', true)
    },
    
    saveAddForm () {
      const vm = this

      if(vm.$refs.form.validate()){
        vm.attachForm.id_module_data = vm.idLetter
        vm.dialogConfirm = true
      }
      else{
        vm.toast = {
          show: true, color:'red', text: 'Form still has an error!', timeout: 2000
        }
      }
    },
    insertUploadSurat () {
      const vm = this
      vm.dialogConfirm = false
      vm.isLoading = true
      
      insertMasterAttachment(vm.attachForm).then(responseL => {
        var respL = responseL.data
        vm.isLoading = false
        if (respL.id > 0) {
          if (vm.attachment.file !== null) {
            vm.uploadAttachment(respL.id)
          }
        }
      })
      .catch(err => {
        // console.log(err)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'red', text: 'Submit Share Surat GAGAL, silakan dicoba kembali.', timeout: 2000
        }
      })          

      
    },  
    beforeFileUpload (file) {
      /*
      var isFormatOK = false
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        isFormatOK = true
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isFormatOK) {
        this.$message.error('Avatar picture must be JPG format!')
      }
      if (!isLt2M) {
        this.$message.error('Avatar picture size can not exceed 2MB!')
      }
      return isFormatOK && isLt2M
      */
      return true
    },
    filesChange (uploadfile) {
      this.attachment.file = uploadfile[0]
      // console.log(this.attachment.file)
    },
    addAttachment (file, fileList) {
      this.attachment.file = file
      // console.log(this.attachment.file)
    },
    uploadAttachment (refID) {
      const vm = this
      // console.log('uploadAttachment',vm.attachment)
      if (vm.attachment.file) {
        var file = vm.attachment.file
        if (vm.beforeFileUpload(file)) {
          if (refID !== 0) {
            vm.uploadFileAttachment(refID, file)
          }
          else {
            vm.toast = {
              show: true, color:'red', text: 'RefID File NOT Found.', timeout: 2000
            }
          }
        }
      }
    },
    uploadFileAttachment (refID, file) {
      const vm = this
      // console.log('uploadFileAttachment')
      var token = secureStorage.getItem('jwt')
      if (token.length === 0) return null

      const bodyFormData = new FormData()
      bodyFormData.append('refId', refID)
      bodyFormData.append('field', 'attachment')
      bodyFormData.append('ref', 'masterattachment')
      // bodyFormData.append('source', 'masterattachments')
      bodyFormData.append('path', 'masterattachment/attachment')
      // Add each file upload to the form data
      bodyFormData.append('files', file, file.name)
      // console.log(file.name)
      var uri = _GLOBAL.URLBACKEND + '/upload'
      
      vm.isupLoading = true
      vm.uploadPercentage = 0
      axios.post(uri,
        bodyFormData,
        {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`
          },
          onUploadProgress: function( progressEvent ) {
            vm.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))
          }.bind(vm)
        }
      ).then(function(response){
        // console.log('SUCCESS!!');
        // console.log(response.data)

        vm.isupLoading = false
        vm.toast = {
          show: true, color:'green', text: 'File Lampiran telah BERHASIL tersimpan.', timeout: 2000
        }
        vm.resetForm()
        vm.$emit('dialogAttachmentClose', true)
      })
      .catch(function(err){
        // console.log('FAILURE!!');
        // console.log(err)
          vm.isupLoading = false
          vm.toast = {
            show: true, color:'red', text: 'File Lampiran GAGAL tersimpan, silakan dicoba kembali.', timeout: 2000
          }
      });
    }  
  }
}

</script>

<style>
.v-input--selection-controls {
    margin: 0;
    padding: 0; 
}
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.7) !important;
}
</style>
