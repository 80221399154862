<template>
  <v-container>
    <v-form ref="form" v-model="valid">
      <v-card
        class="pa-md-2 mx-lg-auto"
        outlined
      >

      <v-row>
          <v-col
            cols="12"
            md="12"
          >
          <table>
            <tr v-if="perihalsurat != ''">
              <td width="150px" style="font-size:12px">HAL</td>
              <td width="10px">:</td>
              <td class="subtitle-2"><span v-html="perihalsurat"></span></td>
            </tr>
            <!--<tr>
              <td style="font-size:12px">NAMA </td>
              <td>:</td>
              <td class="subtitle-2"><span v-if="apvldata" v-html="apvldata.toName"></span></td>
            </tr>-->
            <tr>
              <td style="font-size:12px">JABATAN </td>
              <td>:</td>
              <td class="subtitle-2"><span v-if="currrole && currrole.hrposition" v-html="currrole.prefix + ' ' + currrole.hrposition.pos_name"></span></td>
            </tr>
            <tr v-if="currrole && currrole.hrposition && (apvltype == 1 || apvltype == 3) && (posidpenandatangan == currrole.hrposition.id || currrole.id == idpenandatangan)">
              <td style="font-size:12px">STATUS E-SIGN</td>
              <td>:</td>
              <td class="subtitle-2">
                <div v-if="dataUserApproval">
                  <span v-if="dataUserApproval.hasEsign == true">Aktif | {{dataUserApproval.nik_esign}}</span>
                  <span v-else>Tidak Aktif</span>

                </div></td>
            </tr>
            
          </table>
          </v-col>
          
      </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-textarea
              v-model="postForm.approvalComment"
              label="NOTES"
              outlined
              rows="4"
              counter
            ></v-textarea>  
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="4"
            v-if="dataUserApproval && (idpenandatangan == apvldata.hruserpositions_to.id || posidpenandatangan == currrole.hrposition.id)"
          >
            <div v-if="dataUserApproval.hasEsign == true && (apvltype == 1 || apvltype == 3)">
              <v-text-field
                  v-if="dataUserApproval.hasEsign == true && (apvltype == 1 || apvltype == 3)"
                  v-model="postForm.passwordEsign"
                  label="PASSWORD (E-SIGN BSSN)"
                  :type="showPass ? 'text' : 'password'"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPass = !showPass"
                  :rules="[rules.required]"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <v-btn
              color="grey"
              class="mr-4"
              @click="doCancel()"
            >
              Batal
            </v-btn>

            <v-btn :disabled="!valid" 
              color="primary"
              class="mr-4"
              @click="saveEditForm()"
            >
              Submit
            </v-btn>
          
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <span style="font-size:8px;" v-if="apvldata">{{apvldata.id}}</span>

    <v-snackbar
        v-model="toast.show"
        :timeout="toast.timeout"
      >
        {{ toast.text }}
        <v-btn
          :color="toast.color"
          text
          @click="toast.show = false"
        >
          Close
        </v-btn>
      </v-snackbar>

      <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing <span v-if="sCount != 0">{{sCount}}</span></div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>
      
      <v-dialog v-model="dialogConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda ingin melanjutkan proses tersebut?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogConfirm = false">Batal</v-btn>
            <v-btn color="blue darken-1" @click="editApproval()">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</v-container>
</template>

<script>
import secureStorage from '@/utils/secureStorage'
import { doApprove_WF, doDecline_WF, printAndBSSNProcessbyGUID } from '@/api/datasurat'

import { _GLOBAL } from '@/api/server'
import moment from 'moment'

const defaultForm = {
  // eostatus: '',
  approvalComment: '-',
  passwordEsign: ''
}

export default {
  name: 'FormApproval',
  components: {
  },
  props: {
    lguid: {
      type: String,
      default: ''
    },
    perihalsurat: {
      type: String,
      default: ''
    },
    posidpenandatangan:{
      type: Number,
      default: 0
    },
    idpenandatangan: {
      type: Number,
      default: 0
    },
    apvldata: {
      type: Object,
      default: {}
    },
    apvltype: {
      type: Number,
      default: 0
    },
    modelsurat:  {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      postForm: Object.assign({}, defaultForm),
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      valid: false,
      showPass: false,
      rules: {
        required: v => !!v || 'Required.',
        maxlength: v => v.length <= 240 || 'Max 240 characters',
      },
      isLoading: false,
      dialogConfirm: false,
      currrole: [],
      dataUserApproval: null,
      sCount: 0
    }
  },

  created () {
    this.postForm = Object.assign({}, defaultForm)
  },
  destroyed () {
  },
  mounted () {
    const vm = this

    vm.currrole = secureStorage.getItem('currRole')
    console.log('currrole', vm.currrole)
    vm.postForm= Object.assign({}, defaultForm)

    vm.parseUser()
  },
  computed: {

  },
  methods: {
    resetForm () {
      this.$refs.form.reset()
      this.postForm = Object.assign({}, defaultForm)
    },
    doCancel(){
      //  // console.log('back')
      this.$emit('dialogApprovalClose', true)
    },
    parseUser(){
      const vm = this
      var userall = secureStorage.getItem('userall')
      var myRoles = vm.currrole.rolename
      var myUnitCode = vm.currrole.hrorganization.unit_code
      myUnitCode = myUnitCode.toString()

      console.log('apvldata', vm.apvldata)
      
      vm.dataUserApproval = null
      if(userall && vm.apvldata){
        vm.dataUserApproval = null
        // console.log(vm.currrole.id + '=userpos=' + vm.idpenandatangan)
        // console.log(vm.apvldata.hruserpositions_to.hrposition + '=pos=' + vm.currrole.hrposition.id)
        if(vm.currrole.id == vm.idpenandatangan){
          vm.dataUserApproval = userall.find(x => x.id === vm.apvldata.hruserpositions_to.user)
        }else if(vm.apvldata.hruserpositions_to.hrposition == vm.currrole.hrposition.id){
          vm.dataUserApproval = userall.find(x => x.id === vm.currrole.user.id)
        }else if(vm.posidpenandatangan == vm.currrole.hrposition.id){
          vm.dataUserApproval = userall.find(x => x.id === vm.currrole.user.id)
        }

      }

    },

    saveEditForm () {
      const vm = this

      if(vm.$refs.form.validate()){
        
        if(vm.apvltype == 1){ //ESIGN 
          vm.postForm.eostatus = 19
        }else if(vm.apvltype == 2){ //DECLINE
          vm.postForm.eostatus = 6
        }else if(vm.apvltype == 3){ // APPROVE
          if(vm.currrole.id == vm.idpenandatangan || vm.posidpenandatangan == vm.currrole.hrposition.id){
            vm.postForm.eostatus = 4
          }else{
            vm.postForm.eostatus = 8
          }
          
        }else if(vm.apvltype == 4){ // NEED TO REVISE
          vm.postForm.eostatus = 5
        }

        vm.postForm.guid = vm.lguid
        vm.postForm.userpos = vm.currrole.id
        vm.postForm.currIDApproval =  vm.apvldata.id

        console.log(vm.dataUserApproval)
        vm.postForm.hasEsign = vm.dataUserApproval.hasEsign
        vm.postForm.nik_esign = vm.dataUserApproval.nik_esign
        vm.postForm.modelsurat = vm.modelsurat

        // console.log(vm.postForm)
        vm.dialogConfirm = true
      }
      else{
        vm.toast = {
          show: true, color:'red', text: 'Form still has an error!', timeout: 2000
        }
      }
    },   
    countDown(){
      const vm = this
      setTimeout(() => {
        if(vm.sCount == 0){
          vm.isLoading = false
          location.reload()
        }else{
          vm.sCount--
          vm.countDown()
        }
      }, 1000);
    },
    editApproval () {
        const vm = this
        vm.isLoading = true
        console.log('editApproval', vm.apvltype, vm.postForm)

        if(vm.apvltype == 1 || vm.apvltype == 3){ //APPROVE 
          doApprove_WF(vm.postForm).then(response => {
            var data = response.data
            // console.log(data)
            vm.isLoading = false
            vm.toast = {
              show: true, color:'green', text: 'Proses Approve/ tandatangan telah diproses.', timeout: 2000
            }
            vm.resetForm()
            this.$emit('dialogApprovalClose', true)
          }).catch(err => {
            // console.log(err)
            // vm.$router.push('/401')
            vm.isLoading = false
          })
        }else if(vm.apvltype == 2 || vm.apvltype == 4){ //DECLINE
          doDecline_WF(vm.postForm).then(response => {
            var data = response.data
            // console.log(data)
            
            vm.isLoading = false
            vm.toast = {
              show: true, color:'green', text: 'Proses Decline telah diproses.', timeout: 2000
            }
            vm.resetForm()
            this.$emit('dialogApprovalClose', true)
          }).catch(err => {
            // console.log(err)
            // vm.$router.push('/401')
            vm.isLoading = false
          })
        }
        
      },
  }
}

</script>

<style>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-richtexteditor/styles/material.css";
</style>