<template>
  <v-container>
    <v-form ref="form" v-model="valid">
      <v-card
        class="pa-md-2 mx-lg-auto"
        outlined
      >

        <v-row>

           <v-col
          cols="12"
            md="12"
          >
            <v-autocomplete
              v-model="arrhruserpositions_to"
              :items="dataListUserposall"
              label="Tujuan Disposisi (Pilih User):"
              multiple
              chips
              item-text="text"
              item-value="value"
              :rules="[rules.required]">
            </v-autocomplete>
      
          </v-col>

            <v-col
          cols="12"
            md="4"
          >
              <span v-for="(item,i) in dataListPetunjuk" :key="i" >
              <v-checkbox class="text--primary black--text" v-if="i <= totPetunjuk*1/3"  v-model="selJukDispo" :label="item.nmpetunjukdispo" :value="item.id"></v-checkbox>
              </span>
          </v-col>

           <v-col
          cols="12"
            md="4"
          >
               <span v-for="(item,i) in dataListPetunjuk" :key="i" >
              <v-checkbox v-if="i > totPetunjuk*1/3 && i <= totPetunjuk*2/3"  v-model="selJukDispo" :label="item.nmpetunjukdispo" :value="item.id"></v-checkbox>
              </span>
          </v-col>

          <v-col
          cols="12"
            md="4"
          >
               <span  v-for="(item,i) in dataListPetunjuk" :key="i" >
              <v-checkbox v-if="i > totPetunjuk*2/3"  v-model="selJukDispo" :label="item.nmpetunjukdispo" :value="item.id"></v-checkbox>
              </span>
          </v-col>

           <v-col
            cols="12"
            md="6"
          > 
            <v-textarea
              v-model="postForm.subject"
              label="NOTES"
              outlined
              rows="4"
              counter
            ></v-textarea>  
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-dialog
                ref="selTglBtsRespon"
                v-model="selTglRespon"
                :return-value.sync="tglRespon"
                persistent
                width="290px"
              >
              <template v-slot:activator="{ on }">
                <v-text-field 
                  v-model="tglRespon"
                  label="Tgl Batas Respon"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  v-on="on"
                  :rules="[rules.required]"
                ></v-text-field>
              </template>
                <v-date-picker 
                v-model="tglRespon"
                >
                <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="selTglRespon = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.selTglBtsRespon.save(tglRespon)">OK</v-btn>
                </v-date-picker>
                
            </v-dialog>
            
          </v-col>

        </v-row>
        <v-row>
          <v-col class="text-right">
            <v-btn
              color="warning"
              class="mr-4"
              @click="resetForm()"
            >
              Reset Form
            </v-btn>
            <v-btn
              color="grey"
              class="mr-4"
              @click="doCancel()"
            >
              Batal
            </v-btn>

            <v-btn :disabled="!valid" 
              color="primary"
              class="mr-4"
              @click="saveAddForm()"
            >
              Kirim Disposisi
            </v-btn>
          
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <span style="font-size:8px;">{{idLetter}}</span>

    <v-snackbar
        v-model="toast.show"
        :timeout="toast.timeout"
        absolute
        centered
        
      >
        {{ toast.text }}
        <v-btn
          :color="toast.color"
          text
          @click="toast.show = false"
        >
          Close
        </v-btn>
      </v-snackbar>

      <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="emptydispoNotif" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Error</v-card-title>
          <v-card-text><span class="red--text"><b>Belum ada Arahan Disposisi / Tujuan Disposisi masih kosong, Silakan di isi terlebih dahulu!</b></span></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="emptydispoNotif = false">Tutup</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      

      <v-dialog v-model="dialogConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin data tersebut sudah benar?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogConfirm = false">Batal</v-btn>
            <v-btn color="blue darken-1" @click="insertDisposisi()">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</v-container>
</template>

<script>
import secureStorage from '@/utils/secureStorage'
import { insertLetterInOutDisposisi, updateLetterInOut, getSMByUposandLetterIDhasDispo } from '@/api/letterinout'
import { getListPetunjukDisposisiActive } from '@/api/petunjukdisposisi'
import { getListUsrPosActiveAll } from '@/api/hruserpositions'
import {forEach} from 'p-iteration'
import { _GLOBAL } from '@/api/server'
import moment from 'moment'
import axios from 'axios'

const defaultForm = {
  hruserpositions_from: '',
  arrhruserpositions_to: '',
  eostatus_from: '',
  eostatus_to: '',
  subject: '-',
  to_isOpen: 0,
  to_isImportant: 0,
  moduletypes: 1,
  disposisi: '',
  hrpositions_to: '',
  id_letter: '',
  tgl_batas_respon: ''
}

export default {
  name: 'FormDisposisi',
  components: {
  },
  props: {
    idLetter: {
      type: Number,
      default: 0
    },
  },
  data () {
    return {
      postForm: Object.assign({}, defaultForm),
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      valid: false,
      rules: {
        required: v => !!v || 'Required.',
        maxlength: v => v.length <= 240 || 'Max 240 characters',
      },
      isLoading: false,
      dialogConfirm: false,
      emptydispoNotif: false,
      selTglRespon: false,
      selJukDispo: [],
      dataListPetunjuk: [],
      totPetunjuk:0, 
      dataListUserposall: [],
      currrole: [],
      arrhruserpositions_to: [],
      tglRespon: ''
    }
  },
  created () {
    this.postForm = Object.assign({}, defaultForm)
  },
  destroyed () {
  },
  mounted () {
    const vm = this

    vm.currrole = secureStorage.getItem('currRole')
    
    // console.log(vm.currrole)
    vm.getPetunjukDisposisiData()
    vm.parseUser()

    vm.postForm = Object.assign({}, defaultForm)
    vm.tglRespon = moment().add(2, 'days').format('YYYY-MM-DD')
  },
  computed: {

  },
  methods: {
    resetForm () {
      this.$refs.form.reset()
      this.postForm = Object.assign({}, defaultForm)
      this.tglRespon = moment().add(2, 'days').format('YYYY-MM-DD')
    },
    doCancel(){
      //  // console.log('back')
      this.$emit('dialogDispoClose', true)
    },
    parseUser(){
      const vm = this
      
      // console.log( vm.currrole)
      var myRoles = vm.currrole.rolename
      var myUnitCode = vm.currrole.hrorganization.unit_code
      myUnitCode = myUnitCode.toString()

      vm.isLoading = true
      getListUsrPosActiveAll().then(response => {
        
        secureStorage.setItem('userposall',response.data)
        var userposall = response.data
        // console.log('userposall',userposall)

        var dlist = []
        if(userposall){
          userposall.forEach(element => {
            var prefix = element.prefix ? element.prefix + ' ' : ''
            var name = element.fullname + ' ('+  prefix + element.pos_name + ')'
            var tmp = {text: name, value: element.id, unit_code: element.unit_code}
            if(!prefix.includes('Adm') && !prefix.includes('superIT') && !prefix.includes('adm')){
              if(element.unit_code != null){
                var unitcode = element.unit_code
                unitcode = unitcode.toString()
                if(myRoles.includes('dirut') || myRoles.includes('kpa') || myRoles.includes('ppk')){
                  dlist.push(tmp)
                }else if(myRoles.includes('direktur')){
                  var idur = 0 
                  if(vm.currrole.user){
                    idur = vm.currrole.user.id
                  }
                  if(idur == 139){ // Pak Zaid
                    dlist.push(tmp)
                  }else{
                    var resucode = unitcode.substring(0, 2)
                    var compare = myUnitCode.substring(0, 2)
                    if( compare == resucode){
                      dlist.push(tmp)
                    }
                  }
                  
                }else if(myRoles.includes('kadiv') || myRoles.includes('kasat')){
                  var resucode = unitcode.substring(0, 3)
                  var compare = myUnitCode.substring(0, 3)
                  if( compare == resucode){
                    dlist.push(tmp)
                  }
                }
              }
            }
          });
        }

        dlist.sort((a, b) => a.text.localeCompare(b.text))
        vm.dataListUserposall = dlist
        
        vm.isLoading = false
      }).catch(error => {
        // console.log(error)
        vm.isLoading = false
      })

      
      // console.log(vm.dataListUserposall)
    },
    getPetunjukDisposisiData() {
      const vm = this
      getListPetunjukDisposisiActive().then(response => {
        var rData = response.data
        // console.log(rData)
        if (rData !== null) {
          vm.dataListPetunjuk = rData
          vm.totPetunjuk = rData.length
        }
      })
    },

    saveAddForm () {
      const vm = this

      if(vm.$refs.form.validate()){
        delete vm.postForm.id
        vm.postForm.hruserpositions_from = vm.currrole.id
        var posto = []
        if(vm.arrhruserpositions_to){
          var uto = vm.arrhruserpositions_to
          uto.forEach(element => {
            if(element.value){
              posto.push(element.value)
            }else{
              posto.push(element)
            }
          });
        }

        
        // vm.postForm.arrhruserpositions_to = posto.toString()
        vm.postForm.arrhruserpositions_to = posto.join("|");
        vm.postForm.disposisi = vm.selJukDispo.toString()

        
        if(vm.postForm.arrhruserpositions_to == '' || (vm.postForm.disposisi == '' && vm.postForm.subject.length == 1)){
          vm.emptydispoNotif = true
          return false
        }
        // console.log(vm.postForm.disposisi)
        // return false;

        vm.postForm.eostatus_from = 12
        vm.postForm.eostatus_to = 25 // Disposisi
        vm.postForm.moduletypes = 1
        
        vm.postForm.id_letter = vm.idLetter
        vm.postForm.tgl_batas_respon = moment(vm.tglRespon).format('YYYY-MM-DD')

        console.log(vm.postForm)
        vm.dialogConfirm = true
      }
      else{
        vm.toast = {
          show: true, color:'red', text: 'Form still has an error!', timeout: 2000
        }
      }
    },
    insertDisposisi () {
      const vm = this
      vm.dialogConfirm = false

      var crole = secureStorage.getItem('currRole')
      // console.log('crole id', crole.id)
      var isidispo = vm.postForm.disposisi
      vm.isLoading = true
      // console.log(vm.postForm)
      insertLetterInOutDisposisi(vm.postForm).then(response => {
        var data = response.data
        // console.log('response:', data)

        // console.log('idLetter id', vm.idLetter)  
        getSMByUposandLetterIDhasDispo(crole.id, vm.idLetter).then(response => {
          var data = response.data
          // console.log(data)
          if(data.length > 0){
            var i =0
            
            forEach(data, (element) => {
              i++
              //Update log
              // console.log(element)
              var updData = { hasDispo: 1 }
              
              updateLetterInOut(updData, element.id ).then(response => {
                console.log('updateLetterInOut hasDispo true', response.data)
              }).catch(err => {
                console.log('updateLetterInOut hasDispo true', err)
              })
              console.log('data.length', data.length)
              console.log('i', i)
              if(i == data.length){
                vm.$emit('dialogDispoClose', true)
                       vm.toast = {
                  show: true, color:'green', text: 'Submit Disposisi BERHASIL.', timeout: 2000
                }
                vm.resetForm()
              }
            })
            
          }
          vm.isLoading = false
        }).catch(err => {
          console.log('error', err)
          // vm.$router.push('/401')
          vm.isLoading = false
          this.$emit('dialogDispoClose', true)
          vm.resetForm()
        })

      })
      .catch(err => {
        // console.log(err)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'red', text: 'Submit Disposisi GAGAL, silakan dicoba kembali.', timeout: 2000
        }
      })
    },    
  }
}

</script>

<style>
.v-input--selection-controls {
    margin: 0;
    padding: 0; 
}
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.7) !important;
}
</style>
