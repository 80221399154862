<template>
  <v-container fluid>
    <!--viewSuratTTD.vue-->
    <v-alert
      border="bottom"
      colored-border
      color="green accent-4"
      elevation="2"
      v-if="strName != ''"
    >
      <v-row >
      <v-col class="px-2 py-0">
       
        <span class="text-left font-weight-bold"><v-btn to="/dashboard" x-small><v-icon>mdi-home</v-icon></v-btn><br>{{strName}}</span>
      </v-col>
      <v-col class="px-2 py-0">
         <div class="text-center" v-if="dataSurat && dataSurat.signpos_x != null"><v-btn style="cursor: none;"  :color="dataSurat.badgeStatus.color" x-small>{{dataSurat.badgeStatus.text}}</v-btn><br><span v-if="dataSurat.badgeStatus.id == 3 || dataSurat.badgeStatus.id == 16 || dataSurat.badgeStatus.id == 18 || dataSurat.badgeStatus.id == 19">{{ dataSurat.UsrApvlposname }}</span></div>
      </v-col>
      <v-col class="text-right px-2 py-0" v-if="dataSurat && dataSurat.badgeStatus">
        <span class="font-weight-normal">DOC:&nbsp;</span><span class="font-weight-bold blue--text" v-if="vJenisSurat">{{ vJenisSurat ? vJenisSurat : '-' }}</span>
        <br> 
        
          <v-btn v-if="(dataSurat.badgeStatus.id == 19 || dataSurat.badgeStatus.id == 3) && currRole.rolename == 'admin' " small color="black" @click="confirmReDraftSurat()">
            <v-icon>mdi-refresh</v-icon>REDRAFT SURAT
          </v-btn>
          &nbsp;
          <v-btn v-if="currRole && dataSurat.badgeStatus.id == 19" small color="purple" @click="showAddForm()">
            <v-icon>mdi-pencil</v-icon>BALAS SURAT
          </v-btn> 
      </v-col>
    </v-row>
    </v-alert>
    
    <v-row>
      <v-col
        class="mx-auto px-3 py-3"
        cols="12"
        md="6"
      >
        <!--<ejs-pdfviewer height="85vH" id="pdfViewer" :serviceUrl="serviceUrl" documentPath="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"> </ejs-pdfviewer>-->
        <!--<div v-if="dataSurat.hruserpositions_createdBy">
          <div v-if="dataSurat.hruserpositions_createdBy.id == currRole.id">
          <v-btn v-if="!dataSurat.signedSurat" small color="red" @click="checkEsignCoordinate()">
            <v-icon>mdi-clipboard-flow</v-icon>Check Koordinat '$$$' PDF
          </v-btn> 
          <span style="font-size:10px" v-if="!dataSurat.signedSurat">Halaman TTD: <b>{{ dataSurat.sign_page }}</b>, <span v-if="dataSurat" style="font-size:10px">X: <b>{{ dataSurat.signpos_x }}</b></span>, <span style="font-size:10px" v-if="dataSurat">Y: <b>{{ dataSurat.signpos_y }}</b></span></span>
          </div>
        </div>
        -->
        
        <div v-if="dataSurat && dataSurat.badgeStatus">
          <v-btn small color="green" @click="gotoCase(dataSurat.caseGUID)">
            <v-icon>mdi-arrow-left-bold-hexagon-outline</v-icon>CASE SURAT
          </v-btn> 
          &nbsp;
          <v-btn v-if="dataSurat.badgeStatus.id == 5 || dataSurat.badgeStatus.id == 3 || dataSurat.badgeStatus.id == 16 || dataSurat.badgeStatus.id == 18 || dataSurat.badgeStatus.id == 11" small color="red" @click="gotoEditor(dataSurat.letterGUID, dataSurat.idmodel)">
            <v-icon>mdi-pencil</v-icon>UBAH
          </v-btn> 
          <v-btn v-if="dataSurat.eolettertype && dataSurat.badgeStatus.id == 5 || dataSurat.badgeStatus.id == 3 || dataSurat.badgeStatus.id == 16 || dataSurat.badgeStatus.id == 18 || dataSurat.badgeStatus.id == 11" small color="red" @click="editProcess(dataSurat.idmodel, dataSurat.letterGUID, dataSurat.caseGUID, dataSurat.eolettertype)">
            <v-icon>mdi-pencil</v-icon>UBAH HEADER
          </v-btn> 
        
        </div>

        <vue-pdfjs v-if="pdfRefresh" ref="pdfReader" height="90vH" :url="linkScan" :viewer="uripdfViewer"/>
        <div v-if="dataSurat && dataSurat.badgeStatus">
          <span style="font-size:10px" v-if="!dataSurat.signedSurat">Halaman TTD: <b>{{ dataSurat.sign_page }}</b>, <span v-if="dataSurat" style="font-size:10px">X: <b>{{ dataSurat.signpos_x }}</b></span>, <span style="font-size:10px" v-if="dataSurat">Y: <b>{{ dataSurat.signpos_y }}</b></span>&nbsp; &nbsp; <v-btn @click="checkEsignCoordinate()"  small color="blue"><v-icon>mdi-refresh</v-icon>Recheck Koordinat
          </v-btn> </span>
        </div>
        <p style="font-size:10px;">If the file not show, please click here: 
          <a class="green--text" v-if="linkScan" :href="linkScan" target="_BLANK">{{ linkScan }}</a>
          <a class="red--text"  v-else href="#">File NOT Uploaded!</a>
        </p>
      </v-col>
      <v-col
        class="mx-auto"
        cols="12"
        md="6"
      >
        <v-card class="mx-auto px-3 py-3" style="margin: 0;">
          <v-row>
            <v-col cols="12" md="9">
                <table>
                  <tr>
                    <td width="100px;">NO. SURAT</td>
                    <td width="10px;">:</td>
                    <td class="subtitle-2"><span v-if="dataSurat">{{ dataSurat.noSurat ? dataSurat.noSurat : '-' }}</span></td>
                  </tr>
                  <tr>
                    <td>TGL SURAT</td>
                    <td>:</td>
                    <td class="subtitle-2"><span v-if="dataSurat">{{ dataSurat.tglSurat ? dataSurat.tglSurat : '-' | formatDate }}</span></td>
                  </tr>
                  <tr>
                    <td style="vertical-align:top">YTH</td>
                    <td style="vertical-align:top"> :</td>
                    <td class="subtitle-2"><span v-if="tujuanSurat">
                    
                      <span v-if="tujuanSurat.length > 1">
                        <ul>
                          <li v-for="(item,i) in tujuanSurat" :key="i">
                            <span v-if="item.pos_name">{{item.prefix + ' ' + item.pos_name}} ({{item.fullname}})</span>
                            <span v-else>{{item}}</span>
                            </li>
                        </ul>
                      </span>
                      <span v-else-if="tujuanSurat.length == 1">
                        <span v-if="tujuanSurat[0].pos_name" >{{tujuanSurat[0].prefix + ' ' + tujuanSurat[0].pos_name}} ({{tujuanSurat[0].fullname}})</span>
                        <span v-else v-html="tujuanSurat[0]"></span>
                      </span>
                      <span v-else>
                        <span>NA</span>
                      </span>
                    </span>
                    </td>
                  </tr>
                  <tr>
                    <td style="vertical-align:top">PENANDATANGAN</td>
                    <td style="vertical-align:top"> :</td>
                    <td class="subtitle-2"><span v-if="dataSurat">{{ dataSurat.TTDposname }}</span><br><span>({{ dataSurat.TTDName }}) </span></td>
                  </tr>
                  <tr>
                    <td >HAL</td>
                    <td>:</td>
                    <td class="subtitle-2"><span v-if="dataSurat">{{ dataSurat.perihalSurat ? dataSurat.perihalSurat : '-' }}</span></td>
                  </tr>

                </table>
                
            </v-col>
            <v-col cols="12" md="3">
              <table >
                <tr v-if="vKeamanan == 'Rahasia'">
                  <td width="70px;" ></td>
                  <td width="10px;"></td>
                  <td class="subtitle-2 red--text"><b>R</b></td>
                </tr>
                <tr>
                  <td >SIFAT</td>
                  <td>:</td>
                  <td class=""><span v-if="vSifat">{{ vSifat ? vSifat : '-' }}</span></td>
                </tr>
                </table>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              
              <v-tabs
                background-color="transparent"
                color="basil"
                grow
                v-model="tab"
                show-arrows
                
              >
               <v-tab key="1">
                Status TTD
                </v-tab>
                <v-tab key="2">
                Disposisi
                </v-tab>
                <v-tab key="3">
                Share
                </v-tab>
                <v-tab key="4">
                Comment
                </v-tab>
                <v-tab key="5">
                File Lampiran
                </v-tab>
               
              </v-tabs>
              
              <v-tabs-items v-model="tab">
                <v-tab-item key="1">
                  <v-row>
                  <v-col cols="12">
                   
                    <div v-if="(dataSurat.hruserpositions_createdBy && dataSurat.hruserpositions_createdBy.id == currRole.id || dataSurat.hruserpositions_penandatangan && dataSurat.hruserpositions_createdBy.id == currRole.id) && (dataSurat.badgeStatus.id == 11 || dataSurat.badgeStatus.id == 5) && !selfApproval">
                      <v-btn :disabled="!action.canRequestApproval" small color="warning" @click="dialogstartwfConfirm=true">
                        <v-icon>mdi-clipboard-flow</v-icon>Permintaan Persetujuan / TTD
                      </v-btn>
                      <v-alert v-if="msgHardcopy != ''" 
                      outlined
                      type="warning"
                      prominent
                      border="left">
                        {{msgHardcopy}}
                      </v-alert>
                    </div>
                    
                  </v-col>
                  <v-col cols="12" v-if="msgHardcopy == ''">
                    <v-container fluid class="grey lighten-2 px-5 py-3">
                      <div v-if="dataTTD.length>0">
        
                          <v-data-table
                              :headers="fieldsTTD"
                              :items="dataTTD"
                              class="elevation-1"
                            >

                            <template v-slot:item.name="{ item }">
   
                             <span v-if="item.eostatus && item.eostatus.id == 3"><b>{{ item.tpos }}</b></span>
                             <span v-else><b>{{ item.toName }}</b><br>({{ item.tposname }})</span>
                            </template>
                            <template v-slot:item.sts="{ item }">
                              <v-btn style="cursor: none;" :color="item.badgeStatus.color" x-small>{{item.badgeStatus.text}}</v-btn>
                              <v-alert outlined
                                v-if="item.badgeStatus.id == 18"><span style="font-size:10px;">Silakan Klik E-SIGNING ULANG</span></v-alert>
                                <br>
                              
                              <span v-if="item.approvalComment && item.approvalComment.length > 0" @click="showAprvDetail(item.approvalComment)" style="padding:5px;font-size:9px;color:white;background-color:#777;">Klik Detail</span>
                 
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <span class="pointer" v-on="on" >
                                    <v-btn v-if="item.datasurat.hruserpositions_createdBy == currRole.id  && item.toMobilePhone != '' && item.badgeStatus.id == 3 && !selfApproval" x-small color="purple" @click="showNotifForm(item.toMobilePhone, item.id)">
                                      REMINDER SMS<v-icon>mdi-send</v-icon>
                                    </v-btn> 
                                  </span>
                                </template>
                                <span>Untuk Kirim Reminder Notifikasi SMS kepada Approver</span>
                              </v-tooltip>

                              

                            </template>
                            
                            <template v-slot:item.ket="{ item }">
                             <span style="font-size:11px;" class="font-italic">Tgl.Terima: <b>{{ item.created_at | formatDatetime }}</b></span><br>
                             <span style="font-size:11px;" class="font-italic">Tgl.Respon: <b>{{ item.datetime_approval | formatDatetime }}</b></span>
                            </template>
                            
                            <template v-slot:item.aksi="{ item }">
                       
                              <div  v-if="item.hruserpositions_to.hrposition == currRole.hrposition.id && item.isPenandatangan ==1 && (item.badgeStatus.id == 3 || item.badgeStatus.id == 16 || item.badgeStatus.id == 18)">
                                
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <span class="pointer" v-on="on" ><v-btn x-small color="green" @click="showDialogApproval(item,1)">E-SIGNING</v-btn></span>
                                  </template>
                                  <span>Untuk Proses TANDATANGAN</span>
                                </v-tooltip>
                                &nbsp;
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <span class="pointer" v-on="on" ><v-btn x-small color="red" @click="showDialogApproval(item,2)">Decline</v-btn></span>
                                  </template>
                                  <span>Untuk TIDAK SETUJU</span>
                                </v-tooltip>
                              </div>
                              <div v-else-if="item.hruserpositions_to.hrposition == currRole.hrposition.id && (item.badgeStatus.id == 3 || item.badgeStatus.id == 16 || item.badgeStatus.id == 18)">
                                
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <span class="pointer" v-on="on" ><v-btn x-small color="green" @click="showDialogApproval(item,3)">Approve</v-btn></span>
                                  </template>
                                  <span>Untuk SETUJU</span>
                                </v-tooltip>
                                &nbsp;
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <span class="pointer" v-on="on" ><v-btn x-small color="orange" @click="showDialogApproval(item,4)">Need to Revise</v-btn></span>
                                  </template>
                                  <span>Untuk PERLU REVISI</span>
                                </v-tooltip>
                              </div>
                            
                              <div v-else-if="(item.badgeStatus.id == 3 || item.badgeStatus.id == 16 || item.badgeStatus.id == 18)">
                                
                                <table width="100%">
                                  <tr>
                                    <td><v-icon>mdi-timer-sand</v-icon></td>
                                    <td><v-icon>mdi-timer-sand</v-icon></td>
                                  </tr>
                                </table>
                              </div>
                              <div v-else>
                                
                                <table width="100%">
                                  <tr>
                                    <td><v-icon>mdi-check</v-icon></td>
                                    <td><v-icon>mdi-check</v-icon></td>
                                  </tr>
                                </table>
                              </div>
                            </template>
                          </v-data-table>
                      </div>
                      <div v-else>
                        NA
                      </div>
                    </v-container>
                  </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item key="2">
                <v-row>
                  <v-col cols="12">
                    <v-btn small :disabled="action.canDispo" color="warning" @click="dialogDispo=true">
                      <v-icon>mdi-plus</v-icon>Kirim Disposisi
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-container fluid class="grey lighten-2 px-5 py-3">
                      <v-timeline
                        :align-top="true"
                        :dense="true"
                      >
                        <div v-if="dataDispo.length > 0">
                      
                          <div v-for="(item, n) in dataDispo" :key="n">
                         
                            <v-timeline-item v-if="item.arrTO"
                              :small="true"
                            >
                              <template v-slot:icon>
                                <v-avatar>
                                  <img :src="item.senderavatarURL">
                                </v-avatar>
                              </template>
                              <v-card class="elevation-2">
                                <v-card-title>
                                  <v-col cols="8" class="text-left caption font-weight-medium"><b>{{ item.fromName }}</b> ({{ item.fposname }})</v-col>
                                  <v-col cols="4" class="text-right caption font-weight-medium">{{ item.created_at | formatDatetime }}</v-col>
                                </v-card-title>
                                <v-card-text class="text">    
                                  <ul>
                                    <div v-for="(dsp,i) in item.arrDispo" v-bind:key="i">
                                      <li v-if="dsp != null">
                                        <span class="caption">{{ dsp.nmpetunjukdispo}}</span>
                                      </li>
                                    </div>
                                  </ul>
                                  <v-row>
                                    <v-col cols="12" class="text-left caption font-weight-medium">
                                      <div v-if="item.arrTO && item.hruserpositions_from.id != item.hruserpositions_to.id">
                                        <ul style="list-style-type:none;">
                                          <li v-for="(ito, idxto) in item.arrTO" :key="idxto">
                                            <v-icon>mdi-arrow-right</v-icon>&nbsp;
                                            <b>{{ ito.name }}</b> ({{ ito.toPrefix + ' ' +ito.pos }})
                                          </li>
                                        </ul>
                  
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <div cols="4" class="text-right caption font-weight-medium">Batas Respon: <span v-html="item.vtglRespon"></span></div>
                                  <v-divider></v-divider>
                                  <div class="caption"><span>Komentar:</span><br><span class="font-italic subtitle-2">{{ item.subject }}</span></div>
                                </v-card-text>
                              </v-card>
                            </v-timeline-item>
                          </div>
                        </div>
                        <div v-else>
                          NA
                        </div>
                      </v-timeline>
                    
                    </v-container>
                  </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item key="3">
                  <v-row>
                  <v-col cols="12">
                    <v-btn small color="warning" @click="dialogShare=true">
                      <v-icon>mdi-plus</v-icon>Share Surat
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-container fluid class="grey lighten-2 px-5 py-3">
                      <v-timeline
                        :align-top="true"
                        :dense="true"
                      >
                        <div v-if="dataShare.length > 0">
                          <div v-for="(item, n) in dataShare" :key="n">
                            <v-timeline-item v-if="item.arrTO"
                              :small="true"
                            >
                              <template v-slot:icon>
                                <v-avatar>
                                  <img :src="item.senderavatarURL">
                                </v-avatar>
                              </template>
                              <v-card class="elevation-2">
                                <v-card-title>
                                  <v-col cols="8" class="text-left caption font-weight-medium"><b>{{ item.fromName }}</b> ({{ item.fposname }})</v-col>
                                  <v-col cols="4" class="text-right caption font-weight-medium">{{ item.created_at | formatDatetime }}</v-col>
                                </v-card-title>
                                <v-card-text class="text"> 

                                  <v-row>
                                    <v-col cols="12" class="text-left caption font-weight-medium">
                                      <div v-if="item.arrTO && item.hruserpositions_from.id != item.hruserpositions_to.id">
                                        <ul style="list-style-type:none;">
                                          <li v-for="(ito, idxto) in item.arrTO" :key="idxto">
                                            <v-icon>mdi-arrow-right</v-icon>&nbsp;
                                            <b>{{ ito.name }}</b> ({{ ito.toPrefix + ' ' +ito.pos }})
                                          </li>
                                        </ul>
                  
                                      </div>
                                    </v-col>
                                  </v-row>
                                  
                                  <v-divider></v-divider>
                                  <div class="caption"><span>Komentar:</span><br><span class="font-italic subtitle-2">{{ item.subject }}</span></div>
                                </v-card-text>
                              </v-card>
                            </v-timeline-item>
                          </div>
                        </div>
                        <div v-else>
                          NA
                        </div>
                        </v-timeline>
                    
                    </v-container>
                  </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item key="4">
                  <v-row>
                  <v-col cols="12">
                    <v-btn small color="warning" @click="dialogComment=true">
                      <v-icon>mdi-plus</v-icon>Komentar Surat
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-container fluid class="grey lighten-2 px-5 py-3">
                      <v-timeline
                        :align-top="true"
                        :dense="true"
                      >
                        <div v-if="dataComment.length > 0">
                          <div v-for="(item, n) in dataComment" :key="n">
                            <div v-if="item.hruserpositions_to && item.hruserpositions_to.id > 0">
                    
                              <v-timeline-item v-if="item.arrTO"
                                :small="true"
                              >
                                <template v-slot:icon>
                                  <v-avatar>
                                    <img :src="item.senderavatarURL">
                                  </v-avatar>
                                </template>
                                <v-card class="elevation-2">
                                  <v-card-title>
                                    <v-col cols="8" class="text-left caption font-weight-medium"><b>{{ item.fromName }}</b> ({{ item.fposname }})</v-col>
                                    <v-col cols="4" class="text-right caption font-weight-medium">{{ item.created_at | formatDatetime }}</v-col>
                                  </v-card-title>
                                  <v-card-text class="text">    
                                    <v-row>
                                      <v-col cols="12" class="text-left caption font-weight-medium">
                                        <div v-if="item.arrTO && item.hruserpositions_from.id != item.hruserpositions_to.id">
                                          <ul style="list-style-type:none;">
                                            <li v-for="(ito, idxto) in item.arrTO" :key="idxto">
                                              <v-icon>mdi-arrow-right</v-icon>&nbsp;
                                              <b>{{ ito.name }}</b> ({{ ito.toPrefix + ' ' +ito.pos }})
                                            </li>
                                          </ul>
                    
                                        </div>
                                      </v-col>
                                    </v-row>
                                    
                                    <v-divider></v-divider>
                                    <div class="caption"><span>Komentar:</span><br><span class="font-italic subtitle-2">{{ item.subject }}</span></div>
                                  </v-card-text>
                                </v-card>
                              </v-timeline-item>
                            </div>
                            <div v-else>
                              <v-card class="elevation-2">
                                  <v-card-title>
                                    <v-col cols="8" class="text-left caption font-weight-medium"><b>{{ item.fromName }}</b> ({{ item.fposname }})</v-col>
                                    <v-col cols="4" class="text-right caption font-weight-medium">{{ item.created_at | formatDatetime }}</v-col>
                                  </v-card-title>
                                  <v-card-text class="text">    
                                    <div class="caption"><span>Komentar:</span><br><span class="font-italic subtitle-2">{{ item.subject }}</span></div>
                                  </v-card-text>
                                </v-card>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          NA
                        </div>

                      </v-timeline>
                    
                    </v-container>
                  </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item key="5">
                  <v-row>
                  <v-col cols="12">
                    <v-btn small color="warning" @click="dialogAttachment=true">
                      <v-icon>mdi-plus</v-icon>Upload File Lampiran
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-container fluid class="grey lighten-2 px-5 py-3">
                      <div v-if="dataLampiran.length>0">
        
                          <v-data-table
                              :headers="fieldsAttach"
                              :items="dataLampiran"
                              class="elevation-1"
                            >

                            <template v-slot:item.name="{ item }">
                             <a href="#" @click="goFileLink(item.url)">{{ item.name }}</a>
                            </template>
                            <template v-slot:item.aksi="{ item }">

                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <a href="#" v-on="on" @click="goFileLink(item.url)"><v-icon class="green--text">mdi-eye</v-icon></a>
                                </template>
                                <span>Buka File</span>
                              </v-tooltip>
                              &nbsp;
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <a href="#" v-on="on" @click="confirmDeleteFile(item.id_ms, item.id)"><v-icon class="red--text">mdi-close</v-icon></a>
                                </template>
                                <span>Hapus File</span>
                              </v-tooltip>
                              
                            </template>
                          </v-data-table>
                      </div>
                      <div v-else>
                        NA
                      </div>
                    </v-container>
                  </v-col>
                  </v-row>
                  
                </v-tab-item>
                
              </v-tabs-items> 
            </v-col>
          </v-row>
        </v-card>
        <div class="text-right font-weight-light font-italic" v-if="dataSurat">
          <span style="line-height:0;font-size:11px">Created: {{ dataSurat.created_at | formatDatetime }}, {{ dataSurat.CreatorName }} ( {{dataSurat.Creatorposname}})</span><br>
          <span style="line-height:0;font-size:11px">Updated: {{ dataSurat.updated_at | formatDatetime }}, {{ dataSurat.EditorName }} ( {{dataSurat.Editorposname}})</span>
        </div>
      </v-col>
    </v-row>

    

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="dialogAprvNotes" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Approval Notes</v-card-title>
          <v-card-text class="text-center"><div style="color: blue;background-color: #f0f0f0;;padding:5px;width:100%;">{{ detailAprvNotes }}</div></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogAprvNotes = false">Tutup</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="delConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin ingin meng-HAPUS data tersebut?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="delConfirm = false">Batal</v-btn>
            <v-btn color="blue darken-1" @click="doDeleteData()">Hapus</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogstartwfConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin untuk mulai Permintaan Persetujuan?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogstartwfConfirm = false">Batal</v-btn>
            <v-btn color="green darken-1" @click="doStartPermintaanPersetujuan()">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDispo" persistent max-width="80vW">
        <v-card>
          <v-card-title class="headline">Disposisi</v-card-title>
          <v-card-text>
            <form-disposisi :id-letter="idLetter" @dialogDispoClose="closeDialog(1)" />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogShare" persistent max-width="80vW">
        <v-card>
          <v-card-title class="headline">Share Surat</v-card-title>
          <v-card-text>
            <form-share :id-letter="idLetter" @dialogShareClose="closeDialog(2)" />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogComment" persistent max-width="80vW">
        <v-card>
          <v-card-title class="headline">Komentar</v-card-title>
          <v-card-text>
            <form-comment :participant="listuposParticipant" :id-letter="idLetter" @dialogCommentClose="closeDialog(3)" />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogAttachment" persistent max-width="80vW">
        <v-card>
          <v-card-title class="headline">File Lampiran</v-card-title>
          <v-card-text>
            <form-upload :id-letter="idLetter" @dialogAttachmentClose="closeDialog(4)" />
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogApproval" persistent max-width="80vW" v-if="dataSurat">
        <v-card>
          <v-card-title class="subtitle-2">
            <v-row>
              <v-col cols="12" md="6" class="text-right">
                <span class="font-weight-medium" style="font-size:16px;">Approval Confirmation to:</span>
              </v-col>
              <v-col cols="12" md="6" class="text-left">
                <span v-if="currApvlType == 1"><b><v-badge color="green" content="SIGNED"></v-badge></b></span>
                <span v-else-if="currApvlType == 2"><b><v-badge color="red" content="DECLINED"></v-badge></b></span>
                <span v-else-if="currApvlType == 3"><b><v-badge color="blue" content="APPROVED"></v-badge></b></span>
                <span v-else-if="currApvlType == 4"><b><v-badge color="orange" content="NEED TO REVISED"></v-badge></b></span>
              </v-col>
            </v-row>
            
          </v-card-title>
          <v-card-text>
            <form-approval :modelsurat="modelsurat" :perihalsurat="dataSurat.perihalSurat" :posidpenandatangan="dataSurat.hruserpositions_penandatangan.hrposition" :idpenandatangan="dataSurat.hruserpositions_penandatangan.id" :apvldata="currApvlData" :apvltype="currApvlType" :lguid="letterguid" @dialogApprovalClose="closeDialog(5)" />
          </v-card-text>
        </v-card>
      </v-dialog>

       <v-dialog v-model="dialogredraft" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin untuk me-ReDraft Surat, proses ini akan membutuhkan Persetujuan TTD Ulang?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogredraft = false">Batal</v-btn>
            <v-btn color="green darken-1" @click="doREDraftSurat()">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="notif.dialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin untuk mengirimkan Notif SMS?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="notif.dialog = false">Batal</v-btn>
            <v-btn color="purple darken-1" @click="doNotifSMS()">Kirim SMS</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

  </v-container>
</template>

<script>
  import moment from 'moment'
  import vuePdfjs from 'vue-pdfjs'
  import _ from 'lodash'
  import { splitComponentsByComma } from '@/utils/index'
  import { sendNotifSMS } from '@/api/admin'

  import secureStorage from '@/utils/secureStorage'
  import { getDataSuratByLetterGUID, checkPdfEsignPosition, doStart_WF, updateDataSurat, deleteFileSurat } from '@/api/datasurat'
  import { getListPetunjukDisposisiActive } from '@/api/petunjukdisposisi'
  
  import { resetPegawaiSTbyDatasurat } from '@/api/daftarpegawaiST'
  import { getSMByID, updateLetterInOut, getViewSuratSMDisposisi, getViewSuratSMShare, getViewSuratSMComment} from '@/api/letterinout'
  import { getlistApprovalByIdLetter, getLastPenandatanganApproval, insertMyApproval} from '@/api/letterapproval'
  import { getMasterAttachment, insertMasterAttachment, uploadMasterAttachment, deleteMasterAttachment, deleteFileMasterAttachment } from '@/api/masterattachment'
  import { _GLOBAL } from '@/api/server'

  import formDisposisi from '../components/formDisposisi'
  import formShare from '../components/formShare'
  import formComment from '../components/formComment'
  import formUpload from '../components/formUpload'
  import formApproval from '../components/formApproval'
  import { PdfViewerPlugin, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch } from '@syncfusion/ej2-vue-pdfviewer';

  export default {
    name: 'SuratView5',
    components: { vuePdfjs, formDisposisi, formShare, formComment, formUpload, formApproval },
    props: {
      dSurat: {
        type: Object,
        default: null
      },
      letterguid: {
        type: String,
        default: ''
      },
      modelsurat: {
        type: Number,
        default: 0
      }
    },
    data: () => ({
      tab: 0,
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      isLoading: false,
      dataSurat: {},
      delConfirm: false,
      dialogDispo: false,
      dialogShare: false,
      dialogComment: false,
      dialogAttachment: false,
      dialogstartwfConfirm: false,
      dialogApproval: false,
      dialogredraft: false,
      notif: {
        dialog: false,
        mdn: '',
        msg: '',
        idapproval: 0
      },
      dialogAprvNotes: false,
      detailAprvNotes: '',
      pdfRefresh: true,
      currRole: null,
      serviceUrl:"https://ej2services.syncfusion.com/production/web-services/api/pdfviewer",
      uripdfViewer: _GLOBAL.PORTALURL, // Development
      // uripdfViewer: _GLOBAL.PORTALURL + '/static', // Productions
      fieldsAttach: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Name', value: 'name', align: 'center', class:'subtitle-1' },
        { text: 'Uraian', value: 'notes', align: 'center', class:'subtitle-1' },
        { text: 'Aksi', value: 'aksi', align: 'center', class:'subtitle-1'}
      ],
      fieldsTTD: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Name', value: 'name', align: 'center', class:'subtitle-1' },
        { text: 'Status', value: 'sts', width: 150, align: 'center', class:'subtitle-1' },
        { text: 'Keterangan', value: 'ket', width: 150, align: 'left', class:'subtitle-1' },
        { text: 'Aksi', value: 'aksi', width: 70,align: 'center', class:'subtitle-1'}
      ],
      linkScan:'',
      petunjukDispo: [],
      dataDispo: [],
      dataTTD: [],
      dataComment: [],
      dataShare: [],
      dataLampiran:[],
      posall: [],
      userall: [],
      strName: '',
      idLetter: 0,
      tujuanSurat: null,
      listuposParticipant: [],
      currApvlData: {},
      currApvlType: 0,
      del: {id_ms: 0, idData:0},
      vJenisSurat: '',
      vSifat: '',
      vKeamanan: '',
      vStatus: '',
      action: {
        canRequestApproval: false,
        canDispo: true
      },
      msgHardcopy: '',
      selfApproval: false
    }),
    provide: {
      PdfViewer: [Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch]
    },
    filters: {
      formatDate: function (value) {
        if (!value) return '-'
        var sdate = moment(value).format('DD MMM YYYY')
        return sdate
      },
      formatDatetime: function (value) {
        if (!value) return '-'
        var sdate = moment(value).add(7, 'hours').format('DD/MM/YY HH:mm')
        return sdate
      }
    },
    computed: {
    },
    created () {
      const vm = this
      vm.getPetunjukDispo()
      
      this.currRole = secureStorage.getItem('currRole')
      this.posall = secureStorage.getItem('posall')
      this.userall = secureStorage.getItem('userall')
      // console.log('currRole', this.currRole)
    
      vm.dataSurat = vm.dSurat
      if(vm.dataSurat.signpos_x == null && vm.dataSurat.signpos_y == null){
        vm.checkEsignCoordinate()
      }else{
        this.initDataSurat()
      }      
    },
    destroyed () {
    },
    mounted () {
      const vm = this
      
      setTimeout(() => {
        vm.tab = 0
      }, 1000);
      
    },
    methods: {
      goBack () {
        this.$router.go(-2)
      },
      goFileLink (uri) {
        var open = window.open(uri,'_blank')
        if (!open || open == null || typeof(open)=='undefined'){
          var errmsg = "Turn off your pop-up blocker!\n\nWe try to open the following url:\n"+uri
          console.log(errmsg)
          vm.$alert(errmsg)
        }
      },
      gotoCase(letterGUID){
        this.$router.push('/case/view/' + letterGUID )
      },
      gotoEditor(letterGUID, model){
        const vm = this
        
        // console.log('gotoEditor',letterGUID, model)
        if(model == 4){
          this.$router.push('/case/editord/' + letterGUID )
        }else if(model == 5){
          this.$router.push('/case/editore/' + letterGUID )
        }else if(model == 2){
          this.$router.push('/case/updletterb/' + vm.dataSurat.caseGUID + '/' + vm.dataSurat.eolettertype + '/' + letterGUID )
        }else if(model == 3){
          this.$router.push('/case/updletterc/' + vm.dataSurat.caseGUID + '/' + vm.dataSurat.eolettertype + '/' + letterGUID )
        }else {
          console.log('model surat not detected')
        }
        
      },
      editProcess (idmodel, lGUID, caseGUID, kdsurat) {
        const vm = this
        if(!lGUID || lGUID == '') {
          vm.toast = {
            show: true, color:'red', text: 'letterGUID null, silakan kontak IT Admin BPDPKS', timeout: 2000
          }
        }
        // console.log(idmodel, lGUID, caseGUID, kdsurat)
        if(idmodel == 1){
          vm.$router.push('/case/updlettera/' + caseGUID + '/' + kdsurat + '/' + lGUID)
        }else if(idmodel == 2){
          vm.$router.push('/case/updletterb/' + caseGUID + '/' + kdsurat + '/' + lGUID)
        }else if(idmodel == 3){
          vm.$router.push('/case/updletterc/' + caseGUID + '/' + kdsurat + '/' + lGUID)
        }else if(idmodel == 4){
          vm.$router.push('/case/updletterd/' + caseGUID + '/' + kdsurat + '/' + lGUID)
        }else if(idmodel == 5){
          vm.$router.push('/case/updlettere/' + caseGUID + '/' + kdsurat + '/' + lGUID)
        }else{
          vm.toast = {
            show: true, color:'red', text: 'Model Surat OUT OF RANGE pada Jenis Surat, silakan kontak IT Admin BPDPKS', timeout: 2000
          }
        }
      },
      showAddForm () {
        const vm = this 
        vm.$router.push('/case/crlettermn/'+vm.dataSurat.caseGUID)
      },

      showNotifForm (nohp, idapproval) {
        const vm = this 

        vm.notif.dialog = true
        nohp = nohp.split(' ').join('');
        vm.notif.mdn = nohp
        vm.notif.idapproval = idapproval
        var uril = encodeURIComponent(_GLOBAL.PORTALURL + "/#/surat/v/" + vm.letterguid)
        // var uril = encodeURI(_GLOBAL.PORTALURL + "/#/surat/v/" + vm.letterguid)
        // var uril = _GLOBAL.PORTALURL + "/#/surat/v/" + vm.letterguid
        vm.notif.msg = "eOffice: Waiting Approval, Link: " + uril
      },
      doNotifSMS(){
        const vm = this
        // console.log('doNotifSMS', vm.notif)
        vm.isLoading = true
        vm.notif.dialog = false
        
        sendNotifSMS(vm.notif.mdn, vm.notif.msg, vm.notif.idapproval).then(response => {
          var resp = response.data
          
          if(resp && resp.response_api){
            // console.log('sendNotifSMS resp.response_api', resp.response_api)
            var status = parseInt(resp.response_api.Status)
            var MessageID = resp.response_api.MessageId
            if(status == 1){
              vm.toast = {
                show: true, color:'green', text: 'Kirim Notifikasi SMS berhasil.', timeout: 2000
              }
            }
          }else if(resp.status == -1){
            // console.log('sendNotifSMS resp', resp)
            vm.toast = {
              show: true, color:'red', text: 'SMS hanya boleh dikirim 1x per hari untuk Persetujuan yang sama', timeout: 2000
            }
          }
          vm.isLoading = false
        }).catch(err => {
           console.log('sendNotifSMS err', err)
          vm.isLoading = false
        })  
      },
      getLetterTypes(){
        const vm = this
        vm.isLoading = true
        getListLetterTypesActive().then(response => {
          var data = response.data
          vm.arrLetterTypes = data
          vm.isLoading = false
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
        })
      },
      reloadDataSurat(){
        const vm = this
        console.log('reloadDataSurat')
        vm.isLoading = true
        vm.pdfRefresh = false
        getDataSuratByLetterGUID(vm.letterguid).then(response => {
          var data = response.data
          if(data.length > 0){
            vm.dataSurat = data[0]
            vm.initDataSurat()
          }
          vm.isLoading = false
          vm.pdfRefresh = true
        }).catch(err => {
          vm.isLoading = false
        })  
      },
      initPermission(){
        const vm = this
        // console.log('initPermission')
        vm.msgHardcopy = ''
        if(vm.dataSurat){
          // console.log(vm.dataSurat)
          if((vm.dataSurat.badgeStatus.id == 11 || vm.dataSurat.badgeStatus.id == 5) && vm.dataSurat.hruserpositions_createdBy.id == vm.currRole.id){
            // console.log('as konseptor')
            if(!vm.dataSurat.signpos_x || !vm.dataSurat.signpos_y){
              vm.action.canRequestApproval = false
            }else if(vm.modelsurat == 4 || vm.modelsurat == 5){
              vm.action.canRequestApproval = true
            }else{
              if(vm.dataSurat.TTDhasEsign){
                vm.action.canRequestApproval = true
              }else{
                vm.action.canRequestApproval = false
                vm.msgHardcopy = 'Penandatanganan dilakukan secara Print Hardcopy.'
              }
            }
          }else if(vm.dataSurat.hruserpositions_penandatangan.id == vm.currRole.id){
            vm.action.canRequestApproval = true
            // console.log('as penandatangan', vm.action.canRequestApproval)
          }
        }

        if(vm.currRole){
          var myuser = secureStorage.getItem('user')
          var pref = vm.currRole.prefix != '' ? vm.currRole.prefix + ' ' : ''
          vm.strName = myuser.fullname + ' (' + pref + vm.currRole.hrposition.pos_name + ')'
          var role = vm.currRole.rolename
          if(role === 'kadiv' || role==='kasat' || role==='dirut' || role==='direktur' || role==='kpa' || role==='ppk'){
            if(vm.dataSurat.badgeStatus.id == 19){
              vm.action.canDispo = false
            }
          }
        }
      },
      closeDialog(type){
        const vm = this
        if(type == 1){
          vm.dialogDispo = false
          vm.getDataDisposisi(vm.idLetter)
        } else if(type == 2){
          vm.dialogShare = false
          vm.getDataShare(vm.idLetter)
        } else if(type == 3){
          vm.dialogComment = false
          vm.getDataComment(vm.idLetter)
        } else if(type == 4){
          vm.dialogAttachment = false
          vm.getDataLampiran(vm.idLetter)
        } else if(type == 5){
          vm.dialogApproval = false
          setTimeout(() => {
            location.reload()
          }, 500);
        }
      },
      initStatusYth(){
        const vm = this
        var datauserposall = secureStorage.getItem('userposall')
        let tos = vm.dataSurat.tujuanSurat
        // console.log(tos)
        if(tos){
          tos = tos.toString()
          vm.tujuanSurat = []

          // let arrtos =splitComponentsByComma(tos)
          let arrtos = tos.split('|')
          
          if(arrtos.length > 0 && datauserposall.length > 0){
            arrtos.forEach(idtos => {
              // console.log(idtos)
              let isIntid = Number(idtos)
              // console.log('isIntid', isIntid)
              if(isIntid > 0){
                let tmp = datauserposall.find(x => x.id === isIntid)
                if(tmp){
                  vm.tujuanSurat.push(tmp)
                }
              }else{
                idtos = idtos.split('\n').join('<br>');
                if(idtos != ''){
                  vm.tujuanSurat.push(idtos)
                }
              }
            })
          }
        }

        // console.log(vm.tujuanSurat)
      },
      getUsersToData() {
        const vm = this
        var rData = secureStorage.getItem('userposall')
        if (rData !== null) {
          let temp = []
          rData.forEach(element => {
            if (element != null) {
              if (element.rolename != 'admin' && element.rolename != 'developer' && element.rolename != 'admin_sdm' && element.rolename != 'sekretaris' && element.rolename != 'staff') {
                if (element.prefix != null) { element.prefix = element.prefix + ' ' } else { element.prefix = '' }

                let uposid = element.id
                element['posname'] = element.prefix + element.pos_name
                element['userposname'] = element.fullname + ' (' + element['posname'] + ')'
                element['unitcode'] = parseInt(element.unit_code)

                let tmp = {text:  element['userposname'], value: uposid, unitcode: element['unitcode'], pos_name: element['posname']}
                temp.push(tmp)
              }
            }
          })
          
          temp.sort((a, b) => Number(a.unitcode) - Number(b.unitcode))
          vm.dataListUserTo = temp
          // console.log(vm.dataListUserTo)
        }
        
      },
      getPetunjukDispo () {
        const vm = this
        vm.isLoading = true
        getListPetunjukDisposisiActive().then(response => {
          var data = response.data
          //console.log(data)
          vm.petunjukDispo = []
          if(data.length > 0){
           vm.petunjukDispo = data
          }
          
          // console.log(vm.petunjukDispo)
          vm.isLoading = false
        }).catch(err => {
          // console.log(err)
          
          vm.isLoading = false
        })
      },
      colTglBtsRespon: function (value) {
        if (!value) return ''
        var now = moment().format()
        const dateIsAfter = moment().isAfter(moment(value));
        const dateIsSame = moment().isSame(moment(value));
        const dateIsBefore = moment().isBefore(moment(value));

  
        var sdate = moment(value).format('DD MMM YYYY')
        if(dateIsAfter){
          sdate = '<span class="red--text">' + moment(value).format('DD MMM YYYY') + '</span>'
        }else if(dateIsBefore){
          sdate = '<span class="green--text">' + moment(value).format('DD MMM YYYY') + '</span>'
        }else if(dateIsSame){
          sdate = '<span class="blue--text">' + moment(value).format('DD MMM YYYY') + '</span>'
        }

        return sdate
      },
      
      initDataSurat () {
        const vm = this

        var data = vm.dataSurat
        console.log('initDataSurat')
        if(data){

          vm.idLetter = data.id
          if(data.eosecuritytype){
            vm.vKeamanan = data.eosecuritytype.security_type
            vm.dataSurat.eosecuritytype = data.eosecuritytype.id
          }
          if(data.eosifatsurat){
            vm.vSifat = data.eosifatsurat.sifat
            vm.dataSurat.eosifatsurat = data.eosifatsurat.id
          }

          vm.dataSurat['idmodel'] = 0
          if(data.eolettertype){
            vm.vJenisSurat = data.eolettertype.type_name
            vm.dataSurat['idmodel'] = parseInt(data.eolettertype.modelsurat)
            vm.dataSurat.eolettertype = data.eolettertype.id
          }

          if(data.hrorganizations){
            vm.dataSurat.hrorganizations = data.hrorganizations.id
          }
          
          if(data.signedSurat){
            var tmp = {linkfile: _GLOBAL.URLBACKEND + data.signedSurat.url}
            var obj = vm.dataSurat.signedSurat
            vm.dataSurat.signedSurat = Object.assign(tmp, obj);
            vm.linkScan = _GLOBAL.URLBACKEND + data.signedSurat.url
          }else{
            var tmp = {linkfile: _GLOBAL.URLBACKEND + data.scanSurat.url}
            var obj = vm.dataSurat.scanSurat
            vm.dataSurat.scanSurat = Object.assign(tmp, obj);
            vm.linkScan = _GLOBAL.URLBACKEND + data.scanSurat.url
          }

          if(data.tglSurat){
            vm.dataSurat.tglSurat = moment(data.tglSurat).format('YYYY-MM-DD')
          }

          if(data.thang){
            vm.dataSurat.thang = data.thang.toString()
          }

          vm.dataSurat['badgeStatus'] = { text:'', color: 'white' , id: 0}
          if(data.statusSurat){
            if(data.statusSurat.id == 11){
              vm.dataSurat['badgeStatus'] = { text: data.statusSurat.status_name, color: 'grey' , id: data.statusSurat.id}
            }else if(data.statusSurat.id == 3 || data.statusSurat.id == 16 || data.statusSurat.id == 18 ){
              vm.dataSurat['badgeStatus'] = { text: data.statusSurat.status_name, color: 'blue', id: data.statusSurat.id}
            }else if(data.statusSurat.id == 4 || data.statusSurat.id == 19){
              vm.dataSurat['badgeStatus'] = { text: data.statusSurat.status_name, color: 'green', id: data.statusSurat.id}
            }else if(data.statusSurat.id == 5 || data.statusSurat.id == 6){
              vm.dataSurat['badgeStatus'] = { text: data.statusSurat.status_name, color: 'red', id: data.statusSurat.id}
            } else {
              vm.dataSurat['badgeStatus'] = { text: data.statusSurat.status_name, color: 'grey', id: data.statusSurat.id}
            }
          }

          vm.dataSurat['TTDposname'] = '-'
          if(vm.dataSurat.hruserpositions_penandatangan){
            var arrpos = vm.posall.find(x => x.id === vm.dataSurat.hruserpositions_penandatangan.hrposition)
            vm.dataSurat['TTDposname'] = vm.dataSurat.hruserpositions_penandatangan.prefix ? vm.dataSurat.hruserpositions_penandatangan.prefix + ' ' + arrpos.pos_name : arrpos.pos_name
            vm.dataSurat['TTDName'] = '-'
            var arrusr = vm.userall.find(x => x.id === vm.dataSurat.hruserpositions_penandatangan.user)
            var url = ''
            if(arrusr){
              vm.dataSurat['TTDName'] = arrusr.fullname
              vm.dataSurat['TTDhasEsign'] = false
              if(arrusr.hasEsign){
                vm.dataSurat['TTDhasEsign'] = arrusr.hasEsign
              }
              vm.dataSurat['TTDnik_esign'] = arrusr.nik_esign
              url = _GLOBAL.URLBACKEND + '/images/logo_bpdp.png?imageView2/1/w/80/h/80'
              if(arrusr.avatar){
                url = _GLOBAL.URLBACKEND + arrusr.avatar.url
              }else{
                url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                if (arrusr.senderGender == 'Wanita') {
                  url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                }
              }
            }
            vm.dataSurat['TTDavatarURL'] = url
          }

          vm.dataSurat['UsrApvlposname'] = '-'
          // console.log('vm.dataSurat.hruserpositions_approval',vm.dataSurat.hruserpositions_approval)
          if(vm.dataSurat.hruserpositions_approval && vm.dataSurat.hruserpositions_approval.length > 0){
            var arrpos = vm.posall.find(x => x.id === vm.dataSurat.hruserpositions_approval.hrposition)
            vm.dataSurat['UsrApvlposname'] = vm.dataSurat.hruserpositions_approval.prefix ? vm.dataSurat.hruserpositions_approval.prefix + ' ' + arrpos.pos_name : arrpos.pos_name
            vm.dataSurat['UsrApvlName'] = '-'
            var arrusr = vm.userall.find(x => x.id === vm.dataSurat.hruserpositions_approval.user)
            var url = ''
            if(arrusr){
              vm.dataSurat['UsrApvlName'] = arrusr.fullname
              url = _GLOBAL.URLBACKEND + '/images/logo_bpdp.png?imageView2/1/w/80/h/80'
              if(arrusr.avatar){
                url = _GLOBAL.URLBACKEND + arrusr.avatar.url
              }else{
                url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                if (arrusr.senderGender == 'Wanita') {
                  url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                }
              }
            }
            vm.dataSurat['UsrApvlavatarURL'] = url
          }

          if(vm.dataSurat.hruserpositions_createdBy){
            var arrpos = vm.posall.find(x => x.id === vm.dataSurat.hruserpositions_createdBy.hrposition)
            vm.dataSurat['Creatorposname'] = vm.dataSurat.hruserpositions_createdBy.prefix ? vm.dataSurat.hruserpositions_createdBy.prefix + ' ' + arrpos.pos_name : arrpos.pos_name
            vm.dataSurat['CreatorName'] = '-'
            var arrusr = vm.userall.find(x => x.id === vm.dataSurat.hruserpositions_createdBy.user)
            var url = ''
            if(arrusr){
              vm.dataSurat['CreatorName'] = arrusr.fullname
              url = _GLOBAL.URLBACKEND + '/images/logo_bpdp.png?imageView2/1/w/80/h/80'
              if(arrusr.avatar){
                url = _GLOBAL.URLBACKEND + arrusr.avatar.url
              }else{
                url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                if (arrusr.senderGender == 'Wanita') {
                  url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                }
              }
            }
            vm.dataSurat['CreatoravatarURL'] = url
          }

          if(vm.dataSurat.hruserpositions_editedBy){
            var arrpos = vm.posall.find(x => x.id === vm.dataSurat.hruserpositions_editedBy.hrposition)
            vm.dataSurat['Editorposname'] = vm.dataSurat.hruserpositions_editedBy.prefix ? vm.dataSurat.hruserpositions_editedBy.prefix + ' ' + arrpos.pos_name : arrpos.pos_name
            vm.dataSurat['EditorName'] = '-'
            var arrusr = vm.userall.find(x => x.id === vm.dataSurat.hruserpositions_editedBy.user)
            var url = ''
            if(arrusr){
              vm.dataSurat['EditorName'] = arrusr.fullname
              url = _GLOBAL.URLBACKEND + '/images/logo_bpdp.png?imageView2/1/w/80/h/80'
              if(arrusr.avatar){
                url = _GLOBAL.URLBACKEND + arrusr.avatar.url
              }else{
                url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                if (arrusr.senderGender == 'Wanita') {
                  url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                }
              }
            }
            vm.dataSurat['EditoravatarURL'] = url
          }

          vm.initStatusYth()
          vm.getDataDisposisi(vm.idLetter)
          vm.getDataShare(vm.idLetter)
          vm.getDataComment(vm.idLetter)

          vm.getDataTTDSurat(vm.idLetter)
          vm.getDataLampiran(vm.idLetter)
          // console.log(vm.dataSurat)

          vm.initPermission()


          //Check init self approval
          console.log('getLastPenandatanganApproval', vm.currRole.id, data.hruserpositions_penandatangan.id)
          if(data.hruserpositions_penandatangan && data.statusSurat && vm.currRole.id == data.hruserpositions_penandatangan.id && data.statusSurat.id == 11 && data.id > 0){
            //MyLetter
            vm.selfApproval = true
            getLastPenandatanganApproval(vm.dataSurat.id).then(response => {
              var rData = response.data
              console.log('getLastPenandatanganApproval', rData)
              if(rData.length == 0){
                console.log('insertmyapproval')
                var str = data.perihalSurat
                var perihal = str.substring(0, 100);
                var myapprovdata = {
                  hruserpositions_from: vm.currRole.id,
                  hruserpositions_to: vm.currRole.id,
                  eostatus: 3,
                  isPenandatangan: 1,
                  subject: '[Permintaan TTD] - ' + perihal,
                  deleted: 0,
                  DSignature:'',
                  datasurat: data.id
                }
                vm.isLoading = true
                insertMyApproval(myapprovdata).then(response => {
                  var data = response.data
                  // console.log(data)
                  vm.isLoading = false
                  vm.toast = {
                    show: true, color:'green', text: 'Silakan untuk di TTD.', timeout: 2000
                  }
                  vm.getDataTTDSurat(vm.idLetter)
                }).catch(err => {
                  // console.log(err)
                  // vm.$router.push('/401')
                  vm.isLoading = false
                })
              }
            })
            
          }

        }
        
      },
      getDataDisposisi (id_surat) {
        const vm = this
        vm.isLoading = true
        getViewSuratSMDisposisi(id_surat).then(response => {
          var rData = response.data
          console.log('getViewSuratSMDisposisi', rData)
          vm.dataDispo = []
          if(rData){
            
            var lasttime = ''
            var lastfromid = ''
            var lastnotes = ''
            var lastfromcreateddate = ''
            var lastidx = 0
            var i = 0
            var dataTO = []

            for (i = 0; i < rData.length; i++) {

              rData[i]['arrDispo'] = []
              if(rData[i].disposisi){
                var sDispo = rData[i].disposisi
                var mapdsp = sDispo.split(",").map(Number);
                var ardsp = []
                
                mapdsp.forEach(el => {
                  var tmp = vm.petunjukDispo.find(x => x.id === el)
                  ardsp.push(tmp)
                });
                rData[i]['arrDispo'] = ardsp
              }

              rData[i]['vtglRespon'] = '-'
              if(rData[i].tgl_batas_respon){
                rData[i]['vtglRespon'] = vm.colTglBtsRespon(rData[i].tgl_batas_respon)
              }

              rData[i]['fposname'] = '-'
              if(rData[i].hruserpositions_from){
                var arrpos = vm.posall.find(x => x.id === rData[i].hruserpositions_from.hrposition)
                rData[i]['fposname'] = rData[i].hruserpositions_from.prefix ? rData[i].hruserpositions_from.prefix + ' ' + arrpos.pos_name : arrpos.pos_name
                rData[i]['fromName'] = '-'
                var arrusr = vm.userall.find(x => x.id === rData[i].hruserpositions_from.user)
                var url = ''
                if(arrusr){
                  rData[i]['fromName'] = arrusr.fullname
                  url = _GLOBAL.URLBACKEND + '/images/logo_bpdp.png?imageView2/1/w/80/h/80'
                  if(arrusr.avatar){
                    url = _GLOBAL.URLBACKEND + arrusr.avatar.url
                  }else{
                    url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                    if (arrusr.senderGender == 'Wanita') {
                      url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                    }
                  }
                }
                rData[i]['senderavatarURL'] = url
                vm.listuposParticipant.push(rData[i].hruserpositions_from.id)
              }

              rData[i]['tposname'] = '-'
              if(rData[i].hruserpositions_to && rData[i].hruserpositions_to.id > 0){
                var arrpos = vm.posall.find(x => x.id === rData[i].hruserpositions_to.hrposition)
                rData[i]['tposname'] = rData[i].prefix ? rData[i].prefix + ' ' + arrpos.pos_name : arrpos.pos_name
                rData[i]['tpos'] = arrpos.pos_name
                rData[i]['toName'] = '-'
                var arrusr = vm.userall.find(x => x.id === rData[i].hruserpositions_to.user)
                var url = ''
                if(arrusr){
                  rData[i]['toName'] = arrusr.fullname
                  url = _GLOBAL.URLBACKEND + '/images/logo_bpdp.png?imageView2/1/w/80/h/80'
                  if(arrusr.avatar){
                    url = _GLOBAL.URLBACKEND + arrusr.avatar.url
                  }else{
                    url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                    if (arrusr.senderGender == 'Wanita') {
                      url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                    }
                  }
                }
                rData[i]['toavatarURL'] = url
                vm.listuposParticipant.push(rData[i].hruserpositions_to.id)
              }

              var creatednow = moment(rData[i]['created_at'])
              var diffdispo = 0
              if(lastfromcreateddate != ''){
                diffdispo = lastfromcreateddate.diff(creatednow, 'minutes')
                // console.log('creatednow: ', diffdispo)
              }

              if (rData[i]['hruserpositions_from'].id == lastfromid && rData[i]['subject'] == lastnotes && diffdispo <= 1) {
                var temp = { posonly: rData[i]['tpos'] = arrpos.pos_name, pos: rData[i]['tposname'], name: rData[i]['toName'], toavatarURL: rData[i]['toavatarURL'], posID: rData[i]['hruserpositions_to'].hrposition, toPrefix: rData[i]['hruserpositions_to'].prefix }
                dataTO.push(temp)
                rData[lastidx]['arrTO'] = dataTO
              } else {
                dataTO = []
                lastfromid = rData[i]['hruserpositions_from'].id
                lastnotes = rData[i]['subject']
                lastfromcreateddate = moment(rData[i]['created_at'])
                lastidx = i

                var temp = { posonly: rData[i]['tpos'] = arrpos.pos_name, pos: rData[i]['tposname'], name: rData[i]['toName'], toavatarURL: rData[i]['toavatarURL'], posID: rData[i]['hruserpositions_to'].hrposition, toPrefix: rData[i]['hruserpositions_to'].prefix }
                dataTO.push(temp)
                rData[i]['arrTO'] = dataTO
              }
            }

          }
          vm.dataDispo = rData
          // console.log(vm.dataDispo)
          vm.isLoading = false
        }).catch(err => {
          // console.log(err)
          
          vm.isLoading = false
        })
      },
      checkEsignCoordinate (){
        const vm = this
        vm.isLoading = true

        console.log('checkEsignCoordinate',vm.letterguid)
        checkPdfEsignPosition(vm.letterguid).then(response => {
          var data = response.data

          // console.log('data', data)
          vm.isLoading = false
        }).catch(err => {
          vm.isLoading = false
          // console.log('err', err)
        })

        setTimeout(() => {
          vm.reloadDataSurat()
        }, 1000);
        
      },
      getDataTTDSurat (id_surat) {
        const vm = this
        vm.isLoading = true
        getlistApprovalByIdLetter(id_surat).then(response => {
          var data = response.data
          // console.log('getlistApprovalByIdLetter', data)
          vm.dataTTD = []
          if(data){
            data.forEach(element => {
              console.log(element)
              element['tposid'] = '-'
              element['tpos'] = '-'
              element['tposname'] = '-'
              if(element.hruserpositions_to){

                var arrpos = vm.posall.find(x => x.id === element.hruserpositions_to.hrposition)
                element['tposid'] = element.hruserpositions_to.hrposition
                element['tposname'] = element.hruserpositions_to.prefix ? element.hruserpositions_to.prefix + ' ' + arrpos.pos_name : arrpos.pos_name
                element['tpos'] = arrpos.pos_name
                element['toName'] = '-'
                element['toMobilePhone'] = ''
                var arrusr = vm.userall.find(x => x.id === element.hruserpositions_to.user)
                var url = ''
                if(arrusr){
                  element['toName'] = arrusr.fullname
                  element['toMobilePhone'] = arrusr.phoneNumber
                  
                  url = _GLOBAL.URLBACKEND + '/images/logo_bpdp.png?imageView2/1/w/80/h/80'
                  if(arrusr.avatar){
                    url = _GLOBAL.URLBACKEND + arrusr.avatar.url
                  }else{
                    url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                    if (arrusr.senderGender == 'Wanita') {
                      url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                    }
                  }
                }
                element['toavatarURL'] = url

                element['badgeStatus'] = { text:'', color: 'white' , id: 0}
                if(element.eostatus){
                  if(element.eostatus.id == 11){
                    element['badgeStatus'] = { text: element.eostatus.status_name, color: 'grey' , id: element.eostatus.id}
                  }else if(element.eostatus.id == 3 || element.eostatus.id == 16 || element.eostatus.id == 18 ){
                    element['badgeStatus'] = { text: element.eostatus.status_name, color: 'blue', id: element.eostatus.id}
                  }else if(element.eostatus.id == 4 || element.eostatus.id == 19){
                    element['badgeStatus'] = { text: element.eostatus.status_name, color: 'green', id: element.eostatus.id}
                  }else if(element.eostatus.id == 5 || element.eostatus.id == 6){
                    element['badgeStatus'] = { text: element.eostatus.status_name, color: 'red', id: element.eostatus.id}
                  } else {
                    element['badgeStatus'] = { text: element.eostatus.status_name, color: 'grey', id: element.eostatus.id}
                  }
                }
              }
            });
          }
          vm.dataTTD = data
          // console.log('dataTTD', vm.dataTTD)
          vm.isLoading = false
        }).catch(err => {
          // console.log(err)
          
          vm.isLoading = false
        })
      },
      getDataShare (id_surat) {
        const vm = this
        vm.isLoading = true
        getViewSuratSMShare(id_surat).then(response => {
          var rData = response.data
          // console.log(rData)
          vm.dataShare = []
          if(rData){
            var lasttime = ''
            var lastfromid = ''
            var lastnotes = ''
            var lastidx = 0
            var i = 0
            var dataTO = []

            for (i = 0; i < rData.length; i++) {

              rData[i]['fposname'] = '-'
              rData[i]['fpos'] = '-'
              if(rData[i].hruserpositions_from){
                var arrpos = vm.posall.find(x => x.id === rData[i].hruserpositions_from.hrposition)
                rData[i]['fposname'] = rData[i].hruserpositions_from.prefix ? rData[i].hruserpositions_from.prefix + ' ' + arrpos.pos_name : arrpos.pos_name
                rData[i]['fpos'] = arrpos.pos_name
                rData[i]['fromName'] = '-'
                var arrusr = vm.userall.find(x => x.id === rData[i].hruserpositions_from.user)
                var url = ''
                if(arrusr){
                  rData[i]['fromName'] = arrusr.fullname
                  url = _GLOBAL.URLBACKEND + '/images/logo_bpdp.png?imageView2/1/w/80/h/80'
                  if(arrusr.avatar){
                    url = _GLOBAL.URLBACKEND + arrusr.avatar.url
                  }else{
                    url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                    if (arrusr.senderGender == 'Wanita') {
                      url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                    }
                  }
                }
                rData[i]['senderavatarURL'] = url
                vm.listuposParticipant.push(rData[i].hruserpositions_from.id)
              }
              
              rData[i]['tposname'] = '-'
              rData[i]['fpos'] = '-'
              if(rData[i].hruserpositions_to && rData[i].hruserpositions_to.id > 0){
                var arrpos = vm.posall.find(x => x.id === rData[i].hruserpositions_to.hrposition)
                rData[i]['tposname'] = rData[i].prefix ? rData[i].prefix + ' ' + arrpos.pos_name : arrpos.pos_name
                rData[i]['fpos'] = arrpos.pos_name
                rData[i]['toName'] = '-'
                var arrusr = vm.userall.find(x => x.id === rData[i].hruserpositions_to.user)
                var url = ''
                if(arrusr){
                  rData[i]['toName'] = arrusr.fullname
                  url = _GLOBAL.URLBACKEND + '/images/logo_bpdp.png?imageView2/1/w/80/h/80'
                  if(arrusr.avatar){
                    url = _GLOBAL.URLBACKEND + arrusr.avatar.url
                  }else{
                    url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                    if (arrusr.senderGender == 'Wanita') {
                      url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                    }
                  }
                }
                rData[i]['toavatarURL'] = url
                vm.listuposParticipant.push(rData[i].hruserpositions_to.id)
              }

              // console.log(rData[i]['tposname'])

              

              if (rData[i]['hruserpositions_from'].id == lastfromid && rData[i]['subject'] == lastnotes) {
                var temp = { posonly: rData[i]['tpos'], pos: rData[i]['tposname'], name: rData[i]['toName'], toavatarURL: rData[i]['toavatarURL'], posID: rData[i]['hruserpositions_to'].hrposition, toPrefix: rData[i]['hruserpositions_to'].prefix }
                dataTO.push(temp)
                rData[lastidx]['arrTO'] = dataTO
              } else {
                dataTO = []
                lastfromid = rData[i]['hruserpositions_from'].id
                lastnotes = rData[i]['subject']
                lastidx = i

                var temp = { posonly: rData[i]['tpos'], pos: rData[i]['tposname'], name: rData[i]['toName'], toavatarURL: rData[i]['toavatarURL'], posID: rData[i]['hruserpositions_to'].hrposition, toPrefix: rData[i]['hruserpositions_to'].prefix }
                dataTO.push(temp)
                rData[i]['arrTO'] = dataTO
              }
            }
          }
          vm.dataShare = rData
          // console.log(vm.dataShare)
          vm.isLoading = false
        }).catch(err => {
          // console.log(err)
          
          vm.isLoading = false
        })
      },
      getDataComment (id_surat) {
        const vm = this
        vm.isLoading = true
        getViewSuratSMComment(id_surat).then(response => {
          var rData = response.data
          // console.log('getViewSuratSMComment', rData)
          vm.dataComment = []
          if(rData){
            var lasttime = ''
            var lastfromid = ''
            var lastnotes = ''
            var lastidx = 0
            var i = 0
            var dataTO = []

            for (i = 0; i < rData.length; i++) {

              rData[i]['fposname'] = '-'
              rData[i]['fpos'] = '-'
              if(rData[i].hruserpositions_from && rData[i].hruserpositions_from.id > 0){
                var arrpos = vm.posall.find(x => x.id === rData[i].hruserpositions_from.hrposition)
                rData[i]['fposname'] = rData[i].hruserpositions_from.prefix ? rData[i].hruserpositions_from.prefix + ' ' + arrpos.pos_name : arrpos.pos_name
                rData[i]['fpos'] = arrpos.pos_name
                rData[i]['fromName'] = '-'
                var arrusr = vm.userall.find(x => x.id === rData[i].hruserpositions_from.user)
                var url = ''
                if(arrusr){
                  rData[i]['fromName'] = arrusr.fullname
                  url = _GLOBAL.URLBACKEND + '/images/logo_bpdp.png?imageView2/1/w/80/h/80'
                  if(arrusr.avatar){
                    url = _GLOBAL.URLBACKEND + arrusr.avatar.url
                  }else{
                    url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                    if (arrusr.senderGender == 'Wanita') {
                      url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                    }
                  }
                }
                rData[i]['senderavatarURL'] = url
                vm.listuposParticipant.push(rData[i].hruserpositions_from.id)
              }

              rData[i]['tposname'] = '-'
              rData[i]['fpos'] = '-'
              if(rData[i].hruserpositions_to && rData[i].hruserpositions_to.id > 0){
                var arrpos = vm.posall.find(x => x.id === rData[i].hruserpositions_to.hrposition)
                rData[i]['tposname'] = rData[i].prefix ? rData[i].prefix + ' ' + arrpos.pos_name : arrpos.pos_name
                rData[i]['fpos'] = arrpos.pos_name
                rData[i]['toName'] = '-'
                var arrusr = vm.userall.find(x => x.id === rData[i].hruserpositions_to.user)
                var url = ''
                if(arrusr){
                  rData[i]['toName'] = arrusr.fullname
                  url = _GLOBAL.URLBACKEND + '/images/logo_bpdp.png?imageView2/1/w/80/h/80'
                  if(arrusr.avatar){
                    url = _GLOBAL.URLBACKEND + arrusr.avatar.url
                  }else{
                    url = _GLOBAL.URLBACKEND + '/images/iconman.png?imageView2/1/w/80/h/80'
                    if (arrusr.senderGender == 'Wanita') {
                      url = _GLOBAL.URLBACKEND + '/images/iconwoman.png?imageView2/1/w/80/h/80'
                    }
                  }
                }
                rData[i]['toavatarURL'] = url
                vm.listuposParticipant.push(rData[i].hruserpositions_to.id)
              }

              if (rData[i]['hruserpositions_from'].id == lastfromid && rData[i]['subject'] == lastnotes) {
                var temp = { posonly: rData[i]['tpos'], pos: rData[i]['tposname'], name: rData[i]['toName'], toavatarURL: rData[i]['toavatarURL'], posID: rData[i]['hruserpositions_to'].hrposition, toPrefix: rData[i]['hruserpositions_to'].prefix }
                dataTO.push(temp)
                rData[lastidx]['arrTO'] = dataTO
              } else {
                dataTO = []
                lastfromid = rData[i]['hruserpositions_from'].id
                lastnotes = rData[i]['subject']
                lastidx = i

                var temp = { posonly: rData[i]['tpos'], pos: rData[i]['tposname'], name: rData[i]['toName'], toavatarURL: rData[i]['toavatarURL'], posID: rData[i]['hruserpositions_to'].hrposition, toPrefix: rData[i]['hruserpositions_to'].prefix }
                dataTO.push(temp)
                rData[i]['arrTO'] = dataTO
              }
            }
            vm.dataComment = rData
            // console.log('dataComment', vm.dataComment)
          }
          
          vm.isLoading = false
        }).catch(err => {
            // console.log(err)
          
          vm.isLoading = false
        })
      },

      getDataLampiran(idLetter) {
        const vm = this
        vm.dataLampiran = []
        vm.isLoading = true
        getMasterAttachment(1, idLetter).then(response => {
          var rData = response.data
          vm.isLoading = false
          var list = []
          if (rData) {
            rData.forEach(element => {
              if (element.attachment != null) {
                var temp = { id_ms: element.id, id: element.attachment.id, url: _GLOBAL.URLBACKEND + element.attachment.url, name: element.attachment.name, notes: element.notes }
                list.push(temp)
              }
            })
          }
          // console.log(list)
          vm.dataLampiran = list
        })
      },
      confirmReDraftSurat(){
        const vm = this
      
        vm.dialogredraft = true
      },
      doREDraftSurat(){
        const vm = this
        vm.dialogredraft = false
        vm.isLoading = true

        var data = {
          statusSurat: 11
        }
        updateDataSurat(data, vm.dataSurat.id).then(resp1 => {
          var rData = resp1.data
          // console.log(rData)
          vm.toast = {
            show: true, color:'green', text: 'Surat BERHASIL di-Ubah menjadi Draft Kembali.', timeout: 2000
          }

          resetPegawaiSTbyDatasurat(vm.dataSurat.id).then(resp2 => {
            var rData = resp2.data
            console.log(rData)

            if(vm.dataSurat.signedSurat){
              deleteFileSurat(vm.dataSurat.signedSurat.id).then(resp3 => {
                var rData = resp3.data
                // console.log(rData)
                vm.isLoading = false
                location.reload()
              }).catch(function(err){
                // console.log(err)
                vm.isLoading = false
              })
            }else{
              location.reload()
            }
          }).catch(function(err){
            // console.log(err)
            vm.isLoading = false
          })

        }).catch(function(err){
          // console.log(err)
          vm.isLoading = false
        })
      },
      confirmDeleteFile(id_ms, idData) {
        var vm = this
        
        vm.del.id_ms = id_ms
        vm.del.idData = idData

        vm.delConfirm = true
      },
      doDeleteData(){
        const vm = this
        vm.delConfirm = false
        vm.isLoading = true
        deleteMasterAttachment(vm.del.id_ms).then(response => {
          if (response.status == 200) {
            vm.isLoading = true
            deleteFileMasterAttachment(vm.del.idData).then(response => {
              vm.isLoading = false
              if (response.status == 200) {
                vm.toast = {
                  show: true, color:'green', text: 'File Lampiran BERHASIL terhapus.', timeout: 2000
                }
                vm.getDataLampiran(vm.idLetter)
              } else {
                vm.toast = {
                  show: true, color:'red', text: 'File Lampiran GAGAL terhapus, silakan dicoba kembali.', timeout: 2000
                }
              }
              vm.fetchData(vm.letterguid)
            }).catch(err => {
              // console.log(err)
              vm.isLoading = false
            })
          } else {
            vm.toast = {
              show: true, color:'red', text: 'File Lampiran GAGAL terhapus, silakan dicoba kembali.', timeout: 2000
            }
          }
        }).catch(function(err){
          // console.log(err)
          vm.isLoading = false
        });
      },
      doStartPermintaanPersetujuan () {
        const vm = this

        // console.log('doStartPermintaanPersetujuan', vm.letterguid, vm.currRole.id)
        vm.dialogstartwfConfirm = false
        vm.isLoading = true

        doStart_WF(vm.letterguid, vm.currRole.id).then(response => {
          var rData = response.data
          // console.log(rData)
          vm.isLoading = false

          location.reload()
        }).catch(function(err){
          // console.log(err)
          
          vm.isLoading = false
        })
      },
      showDialogApproval(data, type){
        //1: esign
        //2: decline
        //3: approve
        //4: need to revise
        this.currApvlData = data
        this.currApvlType = type
        this.dialogApproval = true
      },
      showAprvDetail(txtAprvDetail){
        const vm = this
        vm.dialogAprvNotes = true
        if(txtAprvDetail && txtAprvDetail.length > 0){
          vm.detailAprvNotes = txtAprvDetail
        }else{
          vm.detailAprvNotes = '-'
        }
        

      }


    }
  }
</script>
<style>
.v-card {
    border-radius: 6px;
    /* margin-top: 30px; */
    margin-bottom: 15px;
}
</style>

<style>

@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';  
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../../../node_modules/@syncfusion/ej2-vue-pdfviewer/styles/material.css";
</style>

